import React, { useState } from "react";
import logo from "../../assets/icon/footerLogo.svg";
import { Link } from "react-router-dom";
import { CACountry } from "../../utils/CA";
import { useTranslation } from "react-i18next";
import Modal from "../modal/Modal";
import wa from "../../assets/icon/whatsapp-3.svg";

function Footer() {
  const { t } = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="w-full bg-emerald-900 h-auto pt-10 flex flex-col justify-center z-40">
      <div className="px-24 flex justify-between w-full">
        <div className="flex ">
          <Link to="/">
            <img src={logo} alt="" width={130} />
            <p className="text-white text-xl">Nomadia Tours</p>
          </Link>
        </div>
        <div className="flex flex-col font-black text-fistash justify-between">
          <Link to="/kyrgyzstan">
            <p className="hover:text-bel">{t("Kyrgyzstan")}</p>
          </Link>
          {CACountry.map((country) => (
            <Link to={country.href} key={country.id}>
              <p className="hover:text-bel">{t(country.title)}</p>
            </Link>
          ))}
        </div>

        <div className="text-end flex flex-col justify-between text-fistash">
          <div className="flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-phone"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" />
            </svg>
            <p>+996 (500) 888 782</p>
          </div>

          <div
            className="flex items-center gap-2 cursor-pointer"
            onClick={() =>
              window.open(
                "https://www.instagram.com/nomadia_tours/",
                "_blank",
                "noopener noreferrer"
              )
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-instagram"
            >
              <rect width="20" height="20" x="2" y="2" rx="5" ry="5" />
              <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
              <line x1="17.5" x2="17.51" y1="6.5" y2="6.5" />
            </svg>
            <p>Instagram</p>
          </div>
          <div className="flex items-center gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="lucide lucide-mail"
            >
              <rect width="20" height="16" x="2" y="4" rx="2" />
              <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
            </svg>
            <p
              className="cursor-pointer"
              onClick={() => window.open("mailto:info@nomadiatours.com")}
            >
              info@nomadiatours.com
            </p>
          </div>
          <div className="flex items-center gap-2">
            <div
              className="border border-fistash border-2 rounded-md p-2 hover:border-bel hover:text-bel cursor-pointer"
              onClick={handleOpenModal}
            >
              <p className="">{t("contactUs")}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col items-center mt-5">
        <div className="w-full px-24">
          <div className="h-2 border border-2 border-fistash bg-fistash rounded-md"></div>
        </div>
        <div className="my-5 text-center text-white text-sm">{t("rights")}</div>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        title="Свяжитесь с нами"
      >
        <div className="flex justify-center items-center">
          <img
            src={wa}
            className="w-14 cursor-pointer hover:scale-110"
            alt=""
            onClick={() =>
              window.open(
                "https://wa.me/+996500888782",
                "_blank",
                "noopener noreferrer"
              )
            }
          />
        </div>
      </Modal>
    </div>
  );
}

export default Footer;
