import {
  About,
  Contact,
  Kyrgyzstan,
  CentralAsia,
  Main,
  Kazahstan,
  Uzbekistan,
  Turkmenistan,
  Tadjikistan,
  DetailTour,
  AdminPanel,
  KyrgyzstanTours,
  AddTour,
  AdminAbout,
  CentralAsiaTours,
  TourDetail,
} from "../page";

import Pictures from "../page/admin/about/Pictures";
import StaffDetails from "../page/admin/staff_details/StaffDetails";

const routes = [
  { path: "/", element: <Main /> },
  { path: "/kyrgyzstan", element: <Kyrgyzstan /> },
  { path: "/central", element: <CentralAsia /> },
  { path: "/about", element: <About /> },
  { path: "/contact", element: <Contact /> },
  { path: "/central/kazakhstan", element: <Kazahstan /> },
  { path: "/central/uzbekistan", element: <Uzbekistan /> },
  { path: "/central/tadjikistan", element: <Tadjikistan /> },
  { path: "/central/turkmenistan", element: <Turkmenistan /> },
  { path: "/tour/:id", element: <DetailTour /> },
  { path: "/admin/sign", element: <AdminPanel /> },
  { path: "/admin/tours/kyrgyzstan", element: <KyrgyzstanTours /> },
  { path: "/admin/tours/addKGTours", element: <AddTour /> },
  { path: "/admin/tours/addCentralAsiaTours", element: <AddTour /> },
  { path: "/admin/tours/centralAsia", element: <CentralAsiaTours /> },
  { path: "/admin/about", element: <AdminAbout /> },
  { path: "/admin/pictures", element: <Pictures /> },
  { path: "/admin/tour_detail/:id", element: <TourDetail /> },
  { path: "/admin/staff_details/:id", element: <StaffDetails /> },
];

export default routes;
