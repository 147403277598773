import React, { useState } from "react";
import Modal from "../modal/Modal";
import useAdminStore from "../../stores/useAdminStore";

function PictureCard({ polaroid }) {
  const [menu, setMenu] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [inn, setInn] = useState("");
  const { deletePolaroid } = useAdminStore();

  const setMenuVisible = () => {
    setMenu(!menu);
  };

  const setDeleteModalVisible = (id) => {
    setDeleteModal(!deleteModal);
    setMenu(!menu);
    setInn(id);
  };

  const setDeleteModalUnvisible = () => {
    setDeleteModal(!deleteModal);
  };

  return (
    <>
      <Modal isOpen={deleteModal} onClose={setDeleteModalUnvisible}>
        <div className="flex flex-col items-center">
          <p className="mb-4">Вы уверены, что хотите удалить картинку?</p>
          <div className="flex space-x-4">
            <button
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
              onClick={() => {
                deletePolaroid(inn);
                setDeleteModalUnvisible();
              }}
            >
              Да
            </button>
            <button
              className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              onClick={setDeleteModalUnvisible}
            >
              Нет
            </button>
          </div>
        </div>
      </Modal>

      <div className="relative mt-20 flex w-full justify-around">
        <div className="w-80 h-80 bg-white flex flex-col shadow-md -rotate-6">
          <div w-72>
            <img
              src={polaroid.image}
              alt=""
              className=" p-5 rounded-sm grayscale"
            />
          </div>
          <div className="flex justify-end">
            {menu && (
              <div className="flex flex-col items-end bg-white border rounded shadow-lg absolute right-3 bottom-20">
                <button className="px-4 py-2 hover:bg-green-200 w-full text-left">
                  Edit
                </button>
                <button
                  className="px-4 py-2 hover:bg-red-200 w-full text-left"
                  onClick={() => setDeleteModalVisible(polaroid.id)}
                >
                  Delete
                </button>
              </div>
            )}
            <button
              className="self-end w-fit rounded-md p-2 mt-2 hover:bg-gray-200"
              onClick={setMenuVisible}
            >
              <b>. . .</b>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default PictureCard;
