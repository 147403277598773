import React, { Suspense } from "react";
import Navbar from "../../components/navbar/Navbar";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Loader from "../../components/loader/Loader";
import SideBar from "../../components/sidebar/SideBar";

function Layout() {
  const { pathname } = useLocation();
  const isAdminPath = pathname.includes("admin");
  const token = localStorage.getItem("token");
  return (
    <div
      className={`flex ${
        isAdminPath && token ? "" : "flex flex-col"
      } min-h-screen`}
    >
      {isAdminPath && token ? <SideBar /> : <Navbar />}
      <main className="flex-1">
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </main>
      {isAdminPath ? null : <Footer />}
    </div>
  );
}

export default Layout;
