import React, { useEffect, useState } from "react";
import LanguageSwitcher from "../languageSwitcher/LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { navLinks } from "../../utils/navLinks";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/icon/logo.svg";

function Navbar() {
  const [isScroll, setIsScroll] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(null);
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 80) {
        setIsScroll(true);
      } else {
        setIsScroll(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <div
        className={`w-full h-12 sticky top-0 z-50 flex items-center duration-500 ${
          isScroll
            ? "bg-bel text-green-900"
            : "bg-transparent text-emerald-700 font-black"
        }`}
      >
        <div className="w-full mx-24 relative flex justify-between items-center">
          <div>
            <Link to="/">
              <img src={logo} alt="" width={150} />
            </Link>
          </div>
          <div className="w-1/2 flex justify-between items-center">
            {navLinks.map((navItem) => (
              <Link to={navItem.href} key={navItem.id}>
                <div className="relative w-full">
                  <p className="">{t(navItem.title)}</p>
                </div>
              </Link>
            ))}
          </div>
          <div className="flex gap-2">
            <div className="flex items-center gap-2">
              {location.pathname === "/" ? (
                <Link to="/admin/sign">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="34"
                    height="34"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="green"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="cursor-pointer"
                  >
                    <path d="M12 8V4H8" />
                    <rect width="16" height="12" x="4" y="8" rx="2" />
                    <path d="M2 14h2" />
                    <path d="M20 14h2" />
                    <path d="M15 13v2" />
                    <path d="M9 13v2" />
                  </svg>
                </Link>
              ) : null}
            </div>
            <div className="flex justify-around items-center w-24 cursor-pointer">
              <LanguageSwitcher />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
