import React, { useEffect } from 'react'
import useAdminStore from '../../../stores/useAdminStore';
import PictureCard from '../../../components/card/PictureCard';

function Pictures() {
  const {getPolaroids, polaroids} = useAdminStore()

  useEffect(()=>{
    getPolaroids()
  },[getPolaroids])


  return (
    <>
        <div className='flex flex-col'>
           <button 
        className="self-end m-4 bg-green-600 text-white font-bold py-2 px-4 rounded hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition ease-in-out duration-150"
        // onClick={()=>setIsModal(!isModal)}
     >
        Добавить картину
      </button>
      <div className='flex'>
      {polaroids.map((polaroid)=>(
          <PictureCard polaroid={polaroid} key={polaroid.id}/>
      ))}
      </div>
      </div>
    </>
  )
}

export default Pictures